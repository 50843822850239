import { BrowserRouter, Route, Routes } from "react-router-dom";
import PaymentPage from "./pages/pay";
import ChargePointLandingLoader from "./pages/chargePointLandingLoader";
import ErrorPage from "./pages/errorPage";
import "./App.css";
import { Suspense } from "react";
import PaymentCompletePage from "./pages/paymentComplete";
import { IntlProvider, useIntl } from "react-intl";
import TranslationWrapper from "./components/translationWrapper";
import { i18n } from "./i18n/langType";
import { SplashPage } from "./pages/splashPage";

//routing is in this function
function App() {
  const intl = useIntl();
  return (
    <div className="App" style={{ height: "100vh" }}>
      <TranslationWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/error" element={<ErrorPage />} />
            <Route path=":chargerId/pay" element={<PaymentPage />} />
            <Route path=":chargerId/complete/:userSessionId" element={<PaymentCompletePage />} />
            <Route path=":chargerId/complete/:userSession" />
            <Route
              path=":chargerId"
              element={
                <Suspense fallback={<div>{intl.formatMessage({ id: i18n.loadingChargePoint })}</div>}>
                  <ChargePointLandingLoader />
                </Suspense>
              }
            />
            <Route path="" element={<SplashPage />} />{" "}
            {/*TODO:: Add error route for no charger, maybe in chargerPage?*/}
          </Routes>
        </BrowserRouter>
      </TranslationWrapper>
    </div>
  );
}

export default App;
